import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Home from '@/views/index.vue';
import Auth from '@/views/auth/Auth.vue';
import Login from '@/views/auth/login.vue';
import Logout from '@/views/auth/logout.vue';


const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        meta: { layout: 'auth' },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: '/logout',
                name: 'Logout',
                component: Logout, 
              },
        ],
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/index2',
        name: 'index2',
        component: () => import('@/views/index2.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/operation/shipments',
        name: 'tickets-list',
        component: () => import('../views/operation/tickets/list.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/operation/dispatches',
        name: 'dispatches-list',
        component: () => import('../views/operation/dispatches/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/operation/dispatches/parcel-movements',
        name: 'dispatches-parcel-movements-list',
        component: () => import('../views/operation/dispatches/parcel-movements/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/operation/manifests',
        name: 'sales-preview',
        component: () => import('../views/operation/sales/preview.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/operation/consolidations',
        name: 'consolidations-list',
        component: () => import('../views/operation/consolidations/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/operation/report/sales',
        name: 'sales-report',
        component: () => import('../views/operation/sales/preview.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/operation/report/routes/sales',
        name: 'routes-sales-report',
        component: () => import('../views/operation/sales/routes_sales.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/operation/report/collections',
        name: 'collections-report',
        component: () => import('../views/operation/collections/preview.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/settings/branches',
        name: 'setting-branch-index',
        component: () => import('../views/settings/branches/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
    {
        path: '/settings/offices',
        name: 'setting-office-index',
        component: () => import('../views/settings/offices/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },

    //Routes for entitiess (agents)
    {
        path: '/entities/companies/agents',
        name: 'entities-companies-agents-index',
        component: () => import('../views/entities/companies/agents/index.vue'),
        meta: { layout: 'app', requiresAuth: false },
    },
   
     //Routes for sub_entity
     {
        path: '/entities/companies/sub_entity',
        name: 'entities-companies-sub_entity-index',
        component: () => import('../views/entities/companies/sub_entity/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },

      //Routes for entity_company_details
      {
        path: '/entities/companies/entity_company_details',
        name: 'entities-companies-entity_company_details-index',
        component: () => import('../views/entities/companies/entity_company_details/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
   
     //Routes for company_entity
     {
        path: '/entities/companies/company_entity',
        name: 'entities-companies-company_entity-index',
        component: () => import('../views/entities/companies/company_entity/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },

      //Routes for company_entity
      {
        path: '/entities/companies/company_entity_category',
        name: 'entities-companies-company_entity_category-index',
        component: () => import('../views/entities/companies/company_entity_category/index.vue'),
        meta: { layout: 'app', requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { left: 0, top: 0 };
        }
    },
});
// router.beforeEach((to, from, next) => {
//     const loggedIn = !!localStorage.getItem('token');
  
//     if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
//       next({ name: 'Login' });
//     } else if (to.name === 'Logout') {
//       store.commit('setLayout', 'login'); 
//       next(); 
//     } else {
//       store.commit('setLayout', to.meta.layout || 'app');
//       next();
//     }
//   });

router.beforeEach((to, from, next) => {
    const loggedIn = !!localStorage.getItem('token');
  
    if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
      next({ name: 'Login', query: { redirect: to.fullPath } });
    } else if (to.name === 'Logout') {
      store.commit('logout'); 
      next({ name: 'Login' });
    } else {
      store.commit('setLayout', to.meta.layout || 'app');
      next();
    }
  });
  
  
  

export default router;
