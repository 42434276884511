import { createStore } from 'vuex';

const store = createStore({
  state: {
    isAuthenticated: false,
    lastActionTime: null,
    layout: 'app',
    is_show_menu: true,
    is_show_sidebar: true,
    is_show_breadcrumb: true,
    is_show_search: false,
    is_dark_mode: false,
    dark_mode: 'light',
    locale: null,
    menu_style: 'horizontal',
    layout_style: 'full',
    countryList: [
      { code: 'en', name: 'English' },
    ],
    // Auth handler
    token: localStorage.getItem('token') || null,
  },
  mutations: {
    toggleSideBar(state, payload) {
      state.is_show_sidebar = payload;
      state.menu_style = payload
    },
    authenticate(state, payload) {
      state.is_show_sidebar = payload;
      state.isAuthenticated = true;
      state.lastActionTime = new Date().getTime();
    },
    logout(state) {
      state.isAuthenticated = false;
      state.lastActionTime = null;
      state.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    },
    updateLastActionTime(state) {
      state.lastActionTime = new Date().getTime();
    },
    setLayout(state, payload) {
      state.layout = payload;
      state.is_show_sidebar = payload;
    },
    setToken(state, token) {
      state.token = token;
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    },
    resetState(state) {
      Object.assign(state, initialState());
    },
  },
  getters: {
    layout(state) {
      return state.layout;
    },
    isAuthenticated(state) {
      return !!state.token;
    },
  },
  actions: {
    authenticate({ commit }, token) {
      commit('authenticate');
      commit('setToken', token);
    },
    logout({ commit }) {
      commit('logout');
    },
    updateLastActionTime({ commit }) {
      commit('updateLastActionTime');
    },
  },
});

export default store;
