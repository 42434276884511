<template>
    <div :class="[$store.state.layout_style, $store.state.menu_style]">
        <component :is="layout">
            <router-view></router-view>
        </component>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import '@/assets/sass/app.scss';
import { useMeta } from '@/composables/use-meta';
import { useStore } from 'vuex';

useMeta({ title: 'Sales Admin' });

const store = useStore();

const layout = computed(() => {
    return store.getters.layout === 'auth' ? 'authLayout' : 'appLayout';
});
</script>

<script>
import authLayout from './layouts/auth-layout.vue';
import appLayout from './layouts/app-layout.vue';

export default {
    components: {
        appLayout,
        authLayout,
    },
};
</script>
