<template>
  <div>Logging out...</div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';


export default {
  name: 'Logout',
  methods: {
    ...mapActions(['logout']),
    async handleLogout() {
      try {
        const token = localStorage.getItem('token');
        console.log(token);

        if (token) {
          await axios.post('http://127.0.0.1:8000/api/v1/user-masters/logout', {}, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
      } catch (error) {
        console.error('Logout error:', error.response ? error.response.data : error.message);
      } finally {
        this.logout();
        this.$router.push({ name: 'Login' });
      }
    },
  },
  created() {
    this.handleLogout();
  },
};
</script>
